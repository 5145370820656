export type SpacingType = {
  spacing?: {
    mt?: string | number;
    mb?: string | number;
    ml?: string | number;
    mr?: string | number;
    pt?: string | number;
    pb?: string | number;
    pl?: string | number;
    pr?: string | number;
  };
};

const getSpacingValue = (key: string, value: string | number) => {
  const cssValue = typeof value === 'number' ? `${value}px` : value;
  switch (key) {
    case 'mt':
      return `margin-top: ${cssValue};`;
    case 'mb':
      return `margin-bottom: ${cssValue};`;
    case 'ml':
      return `margin-left: ${cssValue};`;
    case 'mr':
      return `margin-right: ${cssValue};`;
    case 'pt':
      return `padding-top: ${cssValue};`;
    case 'pb':
      return `padding-bottom: ${cssValue};`;
    case 'pl':
      return `padding-left: ${cssValue};`;
    case 'pr':
      return `padding-right: ${cssValue};`;
    default:
      return '';
  }
};

export const spacingUtils = (props: any) =>
  Object.keys(props.spacing || {})
    .map((key: string) => getSpacingValue(key, props.spacing[key]))
    .join(' ');

import { PUBLIC_IMAGE_BUCKET } from 'config';
import { ImageProps } from 'interfaces';
import styled from 'styled-components';
import Button from 'ui/Button';

export const ImageContainer = styled.div<ImageProps>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(https://${PUBLIC_IMAGE_BUCKET}.s3.amazonaws.com/default_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10rem;
`;

export const SubWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[0]};
  overflow: hidden;
  width: 25%;
  text-align: center;
  padding: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 30%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 55%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-wrap: wrap;
    width: 60%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: wrap;
    width: 80%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-wrap: wrap;
    width: 90%;
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;
`;

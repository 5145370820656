import Intro from 'modules/landing/components/Intro';
import { useClient } from 'modules/settings/providers/ClientProvider';
import Layout from 'ui/LayoutV2';
import SEO from 'ui/SEO';

const Index = () => {
  const { client } = useClient();

  return (
    <>
      <SEO title="Home" description="" />
      <Layout
        headerVariant={client === 'solyco' ? 3 : 2}
        customStyles={{ isBackgroundImage: true }}
      >
        <Intro />
      </Layout>
    </>
  );
};

export default Index;

import styled from 'styled-components';

export const ItemMobileMarg = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 4rem;
  }
  @media (max-width: 680px) and (min-width: ${({ theme }) =>
      theme.breakpoints.sm}) {
    padding: 0 4rem;
  }
`;

export const DesktopCardContainer = styled.div`
  padding: ${({ theme }) => theme.spaces.p16};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spaces.p24};
  align-self: stretch;
  height: fit-content;
  background: ${({ theme }) => theme.colors.grey[0]};
  box-shadow: 0px -1px 0px 0px #dadada inset;
  border-radius: ${({ theme }) => theme.spaces.p4};
`;

export const EntityName = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.p8};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme }) => theme.colors.grey[600]};
`;

export const LinkControl = styled.text<{ letterSpace?: boolean }>`
  text-align: right;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.primary.main};
  cursor: pointer;
`;

export const AlignRight = styled.div`
  text-align: right;
  margin: 0;
`;

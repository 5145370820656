import { ElementType } from 'react';
import styled from 'styled-components';
import { ButtonSize, StyledButtonVariant } from 'ui/uiTypes';

export interface StyledButtonProps {
  inactive?: boolean;
  size?: ButtonSize;
  variant?: StyledButtonVariant;
  as?: ElementType;
  loading?: boolean;
  fontFamily?: 'primary' | 'secondary' | 'tertiary';
  icon?: boolean;
  maxWidth?: string;
  width?: string;
  state?: 'primary' | 'danger' | 'success' | 'info';
  disabled?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  white-space: nowrap;
  line-height: normal;
  border: none;
  justify-content: center;
  align-items: center;
  display: ${({ loading }) => (loading ? 'flex' : 'inline-flex')};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  border-radius: ${({ theme }) => theme.spaces.p4};
  cursor: ${({ inactive }) => (inactive ? 'not-allowed' : 'pointer')};
  gap: ${({ theme }) => theme.spaces.p8};
  padding: ${({ icon, theme }) =>
      icon
        ? `${theme.spaces.p16} ${theme.spaces.p16} ${theme.spaces.p16} ${theme.spaces.p12};`
        : `${theme.spaces.p16} ${theme.spaces.p24};`}
    ${({ fontFamily, theme }) =>
      fontFamily && `font-family: ${theme.fonts[fontFamily]};`}
    ${({ size }) => {
      switch (size) {
        case 'large':
          return `
        height: 3.75rem;
      `;
        case 'standard':
          return `
            height: 2.5rem;
					`;
        case 'small':
          return `
            height: 2rem;
							`;
        default:
          return `
          height: 2.5rem;
				`;
      }
    }}
    ${({ variant, inactive, state, theme }) => {
      const { grey } = theme.colors;
      const colorState =
        !state || state === 'primary'
          ? theme.colors.primary
          : theme.colors.stateColors[state];

      switch (variant) {
        case 'primary':
          return inactive
            ? `color: ${grey[0]};
          background-color: ${grey[300]};
        `
            : `
          color: ${grey[0]};
          background-color: ${colorState.main};

          &:hover {
            background-color: ${colorState.light};
          }

          &:active {
            background-color: ${colorState.dark};
          }
        `;
        case 'secondary':
          return inactive
            ? `color: ${grey[300]};
            border: 1px solid ${grey[300]};
            background: none;
        `
            : `
        color: ${colorState.main};
        border: 1px solid ${colorState.main};
        background: none;

        &:hover {
          color: ${colorState.light};
          border: 1px solid ${colorState.light};
          background: none;
        }

        &:active {
          color: ${colorState.dark};
          border: 1px solid ${colorState.dark};
          background: none;
        }
      `;
        case 'tertiary':
          return inactive
            ? `color: ${grey[300]};`
            : `
          color: ${colorState.main};
          background: none;

        &:hover {
          color: ${colorState.light};
          background-color: ${grey[200]};
        }

        &:active {
          color: ${colorState.dark};
          background-color: ${grey[300]};
        }
      `;
        default:
          return inactive
            ? `color: ${grey[0]};
          background-color: ${grey[300]};
        `
            : `
          color: ${grey[0]};
          background-color: ${colorState.main};

          &:hover {
            background-color: ${colorState.light};
          }

          &:active {
            background-color: ${colorState.dark};
          }
        `;
      }
    }};

  ${({ maxWidth }) => {
    if (maxWidth) {
      return `max-width: ${maxWidth}`;
    }
  }}

  ${({ width }) => {
    if (width) {
      return `width: ${width} !important`;
    }
  }}
`;

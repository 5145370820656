import React from 'react';
import theme from 'stories/theme';

import { TypographyType } from '../types';
import { StyledDisplay } from './styles';

type SizeType = 'xl' | 'large' | 'medium' | 'small' | 'xs' | 'xxs';

type SizeElementType = {
  [key in SizeType]: string;
};
interface DisplayProps extends TypographyType {
  size?: SizeType;
}

export const Display = ({
  children,
  size = 'large',
  customSize,
  customWeight,
  customLineHeight,
  customFontFamily,
  as,
  ...rest
}: DisplayProps) => {
  const fontSizeMap = {
    xl: 60,
    large: 40,
    medium: 32,
    small: 24,
    xs: 18,
    xxs: 14,
  };

  const element = {
    xl: 'h1',
    large: 'h2',
    medium: 'h3',
    small: 'h4',
    xs: 'h5',
  } as SizeElementType;

  return (
    <StyledDisplay
      fontSize={customSize || fontSizeMap[size]}
      fontFamily={customFontFamily || theme.fonts.family.primary}
      fontWeight={customWeight || theme.fonts.weight.medium}
      lineHeight={customLineHeight}
      as={as || element[size]}
      {...rest}
    >
      {children}
    </StyledDisplay>
  );
};

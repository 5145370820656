const theme = {
  fonts: {
    family: {
      primary: 'Produkt',
      secondary: 'Gotham',
      tertiary: 'Gotham Office',
      sfPro: 'SF Pro',
    },
    weight: {
      hairline: '100',
      thin: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semiBold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    size: {
      xs: '0.75rem', // 12px
      sm: '0.875rem', // 14px
      base: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      xl2: '1.5rem', // 24px
      xl3: '1.875rem', // 30px
      xl4: '2.25rem', // 36px
      xl5: '3rem', // 48px
      xl6: '3.75rem', // 60px
    },
  },
  colors: {
    primary: {
      100: '#E2F5FD',
      200: '#C4EBFB',
      450: '#14BCFF',
      500: '#21B4EF',
      600: '#18A2DA',
    },
    grey: {
      0: '#ffffff',
      100: '#f9f9f9',
      200: '#f3f3f5',
      300: '#dadada',
      400: '#aaaaaa',
      500: '#666666',
      600: '#333333',
      700: '#222222',
      1000: '#000000',
    },
    green: {
      200: '#CAEFCF',
      450: '#69D776',
      500: '#67C272',
      600: '#5DB267',
    },
    red: {
      200: '#FFE2E6',
      450: '#DF2A3D',
      500: '#C21F30',
      600: '#AE1E2D',
    },
    mustard: {
      200: '#FFF2D8',
      450: '#F7BD48',
      500: '#E4AD3F',
      600: '#D29D34',
    },
    purple: {
      200: '#E6DBF7',
      450: '#6F41B2',
      500: '#623A9D',
      600: '#55308B',
    },
  },
  spacing: {
    1: '0.25rem', // 4px
    1.25: '0.3125rem', //5px
    1.5: '0.375rem', // 6px
    2: '0.5rem', // 8px
    2.5: '0.625rem', // 10px
    3: '0.75rem', // 12px
    3.5: '0.875rem', // 14px
    4: '1rem', // 16px
    4.5: '1.125rem', // 18px
    5: '1.25rem', // 20px
    5.5: '1.375rem', // 22px
    6: '1.5rem', // 24px
    7: '1.75rem', // 28px
    8: '2rem', // 32px
    9: '2.25rem', // 36px
    10: '2.5rem', // 40px
    11: '2.75rem', // 44px
    12: '3rem', // 48px
    14: '3.5rem', // 56px
    15: '3.75rem', // 60px
    16: '4rem', // 64px
    17: '4.25rem', //68px
    20: '5rem', // 80px
    23: '5.625rem', //90px
    24: '6rem', // 96px
    28: '7rem', // 112px
    28.75: '7.75rem', //124px
    32: '8rem', // 128px
    36: '9rem', // 144px
    40: '10rem', // 160px
    44: '11rem', //  176px
    48: '12rem', // 192px
    52: '13rem', // 208px
    56: '14rem', // 224px
  },
  styles: {
    borderRadius: '5px',
    elevation: {
      press: '0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
      elevation1: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
      elevation2: '0px 8px 16px 0px rgba(0, 0, 0, 0.15)',
      elevation3: '0px 16px 24px 0px rgba(0, 0, 0, 0.15)',
      elevation4: '0px 24px 32px 0px rgba(0, 0, 0, 0.15)',
    },
  },
  breakpoints: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
  },
};

export default theme;

import Head from 'next/head';

type Props = {
  title: string;
  description?: string;
};

const SEO = ({ title = 'Example app', description = 'Example app' }: Props) => (
  <Head>
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    {/* <meta
      name="google-site-verification"
      content="xxxxx"
    /> */}
    <meta name="description" content={description} />
    <title>{title}</title>
  </Head>
);

export default SEO;

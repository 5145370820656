import { css } from 'styled-components';
import { spacingUtils } from 'utils/helpers/spacingUtils';

import { ReusableTypographyStylesProps } from './types';

export const reusableTypographyStyles = css<ReusableTypographyStylesProps>`
  margin: 0;
  padding: 0;
  font-size: ${({ fontSize }) =>
    typeof fontSize === 'number' ? `${fontSize}px` : fontSize};
  font-family: ${({ fontFamily }) => fontFamily};

  ${({ color }) =>
    color &&
    `
		color: ${color};
	`}

  ${({ fontWeight }) =>
    fontWeight &&
    `
		font-weight: ${fontWeight};
	`}

	${({ lineHeight }) =>
    lineHeight &&
    `
		line-height: ${lineHeight};
	`}

  ${spacingUtils};
`;

import { BaseSyntheticEvent, ElementType, forwardRef, ReactNode } from 'react';
import { ButtonSize } from 'ui/uiTypes';

import { StyledButton, StyledButtonProps } from './styles';
export type ButtonProps = {
  children: ReactNode;
  as?: ElementType;
  size?: ButtonSize;
  onClick?:
    | ((e?: MouseEvent) => void)
    | ((e?: BaseSyntheticEvent<any, any, any>) => Promise<void>);
  passReset?: boolean;
  fontFamily?: 'primary' | 'secondary' | 'tertiary';
  width?: string;
  state?: 'primary' | 'danger' | 'success' | 'info';
  type?: string;
} & StyledButtonProps;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      as,
      onClick,
      inactive,
      loading,
      passReset,
      fontFamily,
      width,
      state,
      disabled,
      ...rest
    },
    ref
  ) => (
    <StyledButton
      {...rest}
      as={as}
      inactive={inactive}
      onClick={onClick}
      ref={ref}
      loading={`${loading?.valueOf()}`}
      passReset={passReset}
      fontFamily={fontFamily}
      width={width}
      state={state}
      disabled={disabled || inactive}
    >
      {children}
    </StyledButton>
  )
);

export default Button;

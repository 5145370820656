import { CLIENT_HOMEPAGE, MVP_ENABLED } from 'config';
import { useClient } from 'modules/settings/providers/ClientProvider';
import { useRouter } from 'next/router';
import Button from 'ui/Button';
import LogoFooter from 'ui/Logos/LogoFooter';

import { ImageContainer, SubmitButton, SubWrapper, Wrapper } from './styles';

const Solyco = () => {
  const router = useRouter();
  return (
    <ImageContainer>
      <Wrapper>
        <SubWrapper>
          <LogoFooter client="solyco" />
          <h1 data-testid="hero-title">Welcome to Solyco Capital</h1>
          <h4 data-testid="hero-description">
            Please sign in using the button below.
          </h4>
          <h4>
            Note you will need your mobile device nearby to complete a 2 factor
            authentication.
          </h4>
          <Button
            as={SubmitButton}
            onClick={() => router.push(CLIENT_HOMEPAGE)}
          >
            Sign In
          </Button>
        </SubWrapper>
      </Wrapper>
    </ImageContainer>
  );
};
const Wellspring = () => {
  const router = useRouter();
  return (
    <ImageContainer>
      <Wrapper>
        <SubWrapper>
          <LogoFooter client="wellspring" />
          <h1 data-testid="hero-title">
            Welcome to Wellspring Capital Management
          </h1>
          <h4 data-testid="hero-description">
            Please sign in using the button below.
          </h4>
          <h4>
            Note you will need your mobile device nearby to complete a 2 factor
            authentication.
          </h4>
          <Button
            as={SubmitButton}
            onClick={() => router.push(CLIENT_HOMEPAGE)}
          >
            Sign In
          </Button>
        </SubWrapper>
      </Wrapper>
    </ImageContainer>
  );
};
const JVM = () => {
  const router = useRouter();
  return (
    <ImageContainer mvp={MVP_ENABLED}>
      <Wrapper>
        <SubWrapper>
          <LogoFooter client="jvm" />
          <h1 data-testid="hero-title">Welcome to the JVM Investor Portal</h1>
          <h4 data-testid="hero-description">
            Please sign in using the button below.
          </h4>
          <h4>
            Note you will need your mobile device nearby to complete a 2 factor
            authentication.
          </h4>
          <Button
            as={SubmitButton}
            onClick={() => router.push(CLIENT_HOMEPAGE)}
          >
            Sign Into Investor Portal
          </Button>
        </SubWrapper>
      </Wrapper>
    </ImageContainer>
  );
};

const Metro = () => {
  const router = useRouter();
  return (
    <ImageContainer>
      <Wrapper>
        <SubWrapper>
          <LogoFooter client="metro" />
          <h1 data-testid="hero-title">Welcome to Metropolitan Realty Group</h1>
          <h4 data-testid="hero-description">
            Please sign in using the button below.
          </h4>
          <h4>
            Note you will need your mobile device nearby to complete a 2 factor
            authentication.
          </h4>
          <Button
            as={SubmitButton}
            onClick={() => router.push(CLIENT_HOMEPAGE)}
          >
            Sign In
          </Button>
        </SubWrapper>
      </Wrapper>
    </ImageContainer>
  );
};

const Core = () => {
  const router = useRouter();
  return (
    <ImageContainer>
      <Wrapper>
        <SubWrapper>
          <LogoFooter client="core" />
          <h1 data-testid="hero-title">Welcome to CORE Industrial Partners</h1>
          <h4 data-testid="hero-description">
            Please sign in using the button below.
          </h4>
          <h4>
            Note you will need your mobile device nearby to complete a 2 factor
            authentication.
          </h4>
          <Button
            as={SubmitButton}
            onClick={() => router.push(CLIENT_HOMEPAGE)}
          >
            Sign In
          </Button>
        </SubWrapper>
      </Wrapper>
    </ImageContainer>
  );
};

const RMR = () => {
  const router = useRouter();
  return (
    <ImageContainer>
      <Wrapper>
        <SubWrapper>
          <LogoFooter client="rmr" />
          <h1 data-testid="hero-title">Welcome to The RMR Group</h1>
          <h4 data-testid="hero-description">
            Please sign in using the button below.
          </h4>
          <h4>
            Note you will need your mobile device nearby to complete a 2 factor
            authentication.
          </h4>
          <Button
            as={SubmitButton}
            onClick={() => router.push(CLIENT_HOMEPAGE)}
          >
            Sign In
          </Button>
        </SubWrapper>
      </Wrapper>
    </ImageContainer>
  );
};

const Relevant = () => (
  <Wrapper>
    <SubWrapper>
      <LogoFooter client="relevant" />
      <h1 data-testid="hero-title">Welcome to Acme Capital</h1>
      <h4 data-testid="hero-description">
        We provide financing for lorem ipsum amenta.
      </h4>
    </SubWrapper>
  </Wrapper>
);

export default function Intro() {
  const { client } = useClient();

  const pages = {
    solyco: <Solyco />,
    jvm: <JVM />,
    metro: <Metro />,
    core: <Core />,
    rmr: <RMR />,
    wellspring: <Wellspring />,
  };

  return pages[client] || <Relevant />;
}
